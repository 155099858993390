





















































import { Messages } from "@/models/enums/messages.enum";
import { DataResponseCalendar } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { Vue, Component } from "vue-property-decorator";
@Component
export default class ClosePeriode extends Vue {
  loadingCalendar = false as boolean;
  dataCalendar = [] as DataResponseCalendar[];
  loadingOpen = false as boolean;
  loadingClose = false as boolean;
  form = this.$form.createForm(this, { name: "closePeriod" });
  formRules = {
    period: {
      label: "Period",
      name: "period",
      placeholder: "lbl_type_to_find_placeholder",
      decorator: [
        "period",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  mounted() {
    this.getListCalendar("");
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  close(): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        logisticServices
          .closePeriodInventory(`?period=${value.period}`)
          .then(() => {
            this.$notification.success({
              description: Messages.UPDATE_SUCCESS,
              message: "Success",
              duration: 30,
            });

            this.form.resetFields();
          })
          .catch(e => {
            this.$notification.error({
              description: e.details,
              message: "Error",
              duration: 30,
            });

            this.$notification.error({
              description: Messages.UPDATE_FAIL,
              message: "Error",
              duration: 30,
            });
          });
      }
    });
  }
  handleCancel() {
    this.form.resetFields();
    this.$router.go(-1);
  }
  getListCalendar(valueSearch) {
    let params = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    this.loadingCalendar = true;
    let dataPeriode = [] as any;
    this.dataCalendar = [];
    assetsServices
      .listCalendar(params)
      .then(data => {
        data.data.forEach(element => {
          if (!element.month.includes("Adj")) dataPeriode.push(element);
        });
        this.dataCalendar = dataPeriode;
      })
      .finally(() => (this.loadingCalendar = false));
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
